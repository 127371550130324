/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.map-icon {
  width: 15px;
  height: 15px;
  /* background: rgb(252, 56, 74); */
  border-radius: 50%;
  border-width: 2px;
  border-color: white;
  border-style: solid;
  color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  line-height: 11px;
  position: relative;
}

.map-icon-zero {
  width: 0px;
  height: 0px;
  position: relative;
}

.complex-name {
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 9px;
  color: black;
  background: #f9f5f2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  white-space: nowrap;
  padding: 2px 5px;
  z-index: 300;
}

.complex-name:hover {
  z-index: 400;
}

.ipotekaform .MuiTabs-root {
  background: #87cefa;
}

.ipotekalist th {
  background: #87cefa;
}
